import giftBox from "@theme/icons/outline/gift_box.inline.svg";
import notificationsOff from "@theme/icons/outline/notifications_off.inline.svg";
import search from "@theme/icons/outline/search.inline.svg";
import support from "@theme/icons/outline/support.inline.svg";
import loader from "@theme/icons/outline/loader.inline.svg";
import tournaments from "@theme/icons/outline/tournaments.inline.svg";
import actions from "@theme/icons/outline/actions.inline.svg";
import quests from "@theme/icons/outline/quests.inline.svg";
import current from "@theme/icons/outline/current.inline.svg";

export const iconsOutline: Record<string, string> = {
    giftBox,
    notificationsOff,
    search,
    support,
    loader,
    tournaments,
    actions,
    quests,
    current,
};
